import { css } from "styled-components"; // break widths

import { breakWidth480, breakWidth600, breakWidth840, breakWidth960, breakWidth1280, breakWidth1440, breakWidth1600 } from "../../../eden-style-constants"; // Mobile first breakpoints

export const mediaBreak480w = (...args) => css(["@media (min-width:", "){", ";}"], breakWidth480, css(...args));
export const mediaBreak600w = (...args) => css(["@media (min-width:", "){", ";}"], breakWidth600, css(...args));
export const mediaBreak840w = (...args) => css(["@media (min-width:", "){", ";}"], breakWidth840, css(...args));
export const mediaBreak960w = (...args) => css(["@media (min-width:", "){", ";}"], breakWidth960, css(...args));
export const mediaBreak1280w = (...args) => css(["@media (min-width:", "){", ";}"], breakWidth1280, css(...args));
export const mediaBreak1440w = (...args) => css(["@media (min-width:", "){", ";}"], breakWidth1440, css(...args));
export const mediaBreak1600w = (...args) => css(["@media (min-width:", "){", ";}"], breakWidth1600, css(...args));
export const mediaBreakGutter = (...args) => css(["@media (min-width:", ") and (min-height:", "){", ";}"], breakWidth600, breakWidth600, css(...args));
/* BiDi Helpers */

export const dir = theDir => (...args) => css(["[dir=\"", "\"] &,&[dir=\"", "\"]{", ";}"], theDir, theDir, css(...args));