import { css } from "styled-components";
import {
    fontSize14,
    fontSize16,
    spacing16,
    spacing4,
    grey3,
    grey20,
    grey40,
    text120,
    sans,
    lineHeightTight,
    fontWeightNormal
} from '../../../../eden-style-constants';
export const paddingBlockStartEnd = spacing4;
export const paddingInlineStartEnd = spacing16;
const shared = css(["text-transform:none;overflow:visible;display:inline-flex;align-items:center;justify-content:center;margin:0;border:0;border-radius:0.125rem;cursor:pointer;font-weight:", ";", ";vertical-align:top;text-decoration:none;padding:", " ", ";font-family:", ";line-height:", ";color:", ";", ";&:hover{color:", ";text-decoration:none;}&[disabled],&[disabled]:hover{", ";cursor:not-allowed;border:none;}"], fontWeightNormal, ({
  fullWidth
}) => fullWidth ? "width: 100%" : "max-width: 18rem", paddingBlockStartEnd, paddingInlineStartEnd, sans, lineHeightTight, text120, ({
  small
}) => small ? `font-size: ${fontSize14}; 
        min-height: 2rem;
        ` : `font-size: ${fontSize16};
        min-height: 2.5rem;
        `, text120, ({
  inverted
}) => inverted ? `background: ${grey40};
          color: ${text120};` : `background: ${grey3};
          color: ${grey20};`);
export default shared;