/* Colors */
export const grey2 = "#f7f8f8";
export const grey3 = "#eff0f0"; // onyx10

export const grey5 = "#e0e2e2"; // onyx20

export const grey10 = "#d0d3d3"; //quartz

export const grey15 = "#bdc0c0"; // onyx40

export const grey20 = "#a9adad"; // granite

export const grey25 = "#9da1a1";
export const grey30 = "#878a8c"; // slate

export const grey35 = "#676b6e";
export const grey40 = "#53575b"; // onyx

export const grey60 = "#3a3d40"; // onyx110

export const grey90 = "#0D0F10";
export const yellow5 = "#ffd61a";
export const yellow10 = "#ffb81c"; // citrine

export const yellow15 = "#faa61a";
export const yellow20 = "#f68d2e"; // amber

export const yellow25 = "#e66a1f";
export const yellow30 = "#d45311"; // topaz

export const yellow35 = "#974a07";
export const yellow40 = "#674730"; // bronzite

export const red5 = "#fda1b2";
export const red10 = "#fc7473"; // coral

export const red15 = "#e10f5a";
export const red20 = "#bd0057"; // ruby

export const red25 = "#a6004e";
export const red30 = "#8f124a"; // jasper

export const red35 = "#7d003f";
export const red40 = "#6e0d33"; // garnet

export const blue5 = "#b0eefc";
export const blue10 = "#7de3f4"; // larimar

export const blue15 = "#49cce6";
export const blue20 = "#01b6d1"; // turquiose  / Link hover

export const blue25 = "#007da5";
export const blue30 = "#006184"; // sapphire

export const blue35 = "#005175";
export const blue40 = "#003057"; // azurite

export const green5 = "#d3e952";
export const green10 = "#bed21e"; // olivine

export const green15 = "#93c742";
export const green20 = "#6db344"; // beryl

export const green25 = "#50a83e";
export const green30 = "#318d43"; // emerald

export const green35 = "#206b3f";
export const green40 = "#235c35"; // jade

export const text120 = "#212225"; // onyxtext

export const black = "#000000";
export const white = "#fff";
export const info10 = "#20abd7";
export const info40 = "#157493"; // Link

export const info60 = "#105970";
export const confirmation10 = "#a3d287";
export const confirmation40 = "#74bb49";
export const confirmation60 = "#375a22";
export const warning10 = "#ffd072";
export const warning40 = "#ffae0c";
export const warning60 = "#8f4200";
export const danger10 = "#fc7473";
export const danger40 = "#DC3838";
export const danger60 = "#b00504";
export const highlightBlue = "#e9f7fc";
export const highlightCyan = "#ddfafb";
export const highlightGreen = "#eff7ea";
export const highlightYellow = "#fff8e9";
export const highlightAmber = "#ffefde";
export const highlightRed = "#fee8e7";
export const highlightMagenta = "#fee8fc";
export const highlightViolet = "#f3e9ff";
export const colorFacebook = "#3b5998";
export const colorInstagram = "#3c5a96";
export const colorTwitter = "#00b6f1";
export const colorPinterest = "#cb2027";
export const colorYoutube = "#cd201f";
export const colorOdnok = "#ed812b";
export const colorVk = "#45668e";
/* Box shadows */

export const boxShadowRaised = "0 2px 4px 0 rgba(0, 0, 0, 0.20)";
export const boxShadowDetached = "0 4px 8px 0 rgba(0, 0, 0, 0.20)";
export const boxShadowOverlaid = "0 8px 16px 0 rgba(0, 0, 0, 0.20)";
export const boxShadowPoppedOut = "0 16px 32px 0 rgba(0, 0, 0, 0.20)";
/* Font families */

export const sans = `"Ensign:Sans", Arial, "noto sans" , sans-serif`;
export const serif = `"Ensign:Serif", "Georgia", "Times New Roman", serif`;
export const monospace = `Courier, "Courier New", monospace`;
/* Font Weights */

export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightSemiBold = 600;
export const fontWeightBold = 700;
/* Font sizes */

export const fontSizeRoot = `100%`; // effectively 16px (16px * 100% = 16px) but respects users' special needs, then desired px / 16px (root size) = rem

export const fontSize11 = "0.6875rem";
export const fontSize13 = "0.8125rem";
export const fontSize14 = "0.875rem";
export const fontSize16 = "1rem";
export const fontSize18 = "1.125rem";
export const fontSize20 = "1.25rem";
export const fontSize28 = "1.75rem";
export const fontSize32 = "2rem";
export const fontSize42 = "2.625rem";
/* Line heights */

export const lineHeightTight = 1.2;
export const lineHeight = 1.4;
export const lineHeightLoose = 1.6;
/* Spacings */

export const spacing4 = "0.25rem"; // 4px

export const spacing8 = "0.5rem"; // 8px

export const spacing16 = "1rem"; // 16px

export const spacing24 = "1.5rem"; // 24px

export const spacing32 = "2rem"; // 32px

export const spacing48 = "3rem"; // 48px

export const spacing64 = "4rem"; // 64px

export const spacing96 = "6rem"; // 96px

export const spacing128 = "8rem"; // 128px

/* Breakpoints */

export const breakWidth480 = "480px";
export const breakWidth600 = "600px";
export const breakWidth840 = "840px";
export const breakWidth960 = "960px";
export const breakWidth1280 = "1280px";
export const breakWidth1440 = "1440px";
export const breakWidth1600 = "1600px";
/* Aspect ratios */

export const aspectRatio1By1 = {
  width: 1,
  height: 1,
  key: "1:1"
};
export const aspectRatio5By6 = {
  width: 5,
  height: 6,
  key: "5:6"
};
export const aspectRatio5By8 = {
  width: 5,
  height: 8,
  key: "5:8"
};
export const aspectRatio6By5 = {
  width: 6,
  height: 5,
  key: "6:5"
};
export const aspectRatio8By3 = {
  width: 8,
  height: 3,
  key: "8:3"
};
export const aspectRatio8By5 = {
  width: 8,
  height: 5,
  key: "8:5"
};
export const aspectRatio16By9 = {
  width: 16,
  height: 9,
  key: "16:9"
};
export const aspectRatios = [aspectRatio1By1, aspectRatio5By6, aspectRatio5By8, aspectRatio6By5, aspectRatio8By3, aspectRatio8By5, aspectRatio16By9];
/* Z indexes */

export const zIndexHigherThanPlatform = 200;