import React, { useRef, useState, useEffect } from 'react';
// @ts-ignore
import { Cart, Profile, Search, World } from '../../../eden/eden-icons';
// @ts-ignore
import { Secondary } from '../../../eden/eden-buttons';

//@ts-ignore
import Callout from '@churchofjesuschrist/eden-callout';

interface LinkProps {
    href: string;
    target?: string;
    rel?: string;
}

const LdsAccount = (props: any) => {
    const { isSignedIn, user, accountLinks, resources } = props;
    const [open, setOpen] = useState(false);
    const iconStyle: React.CSSProperties = {
        height: '60px',
        verticalAlign: 'middle'
    };
    const AccountContainerStyle: React.CSSProperties = {
        width: '200px',
        opacity: '1',
        right: '2px',
        position: 'absolute'
    };
    const Padding: React.CSSProperties = {
        padding: '16px',
        width: '200px'
    };
    const Title5: React.CSSProperties = {
        fontFamily: `"Ensign:Sans",Arial,"noto sans" ,sans-serif`,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '17px'
    };
    const Text4: React.CSSProperties = {
        fontFamily: `"Ensign:Sans",Arial,"noto sans" ,sans-serif`,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: '15px'
    };
    const LinkStyle: React.CSSProperties = {
        color: '#212225',
        textDecoration: 'none'
    };
    const accountRef = useRef(null);
    const calloutClass = open ? 'is-open' : 'closed';

    //Added ref to Callout to close after clicking outside of Callout
    const ref: any = useRef();
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [open]);

    const redirct = () => {
        setOpen(false);
        window.location.replace(
            `${user.signOutUrl}?ru=https://signin.churchofjesuschrist.org/signout?goto=https://store.churchofjesuschrist.org`
        );
    };

    return (
        <>
            <Profile
                size='1.27rem'
                style={iconStyle}
                class='header-icon'
                ref={accountRef}
                color={user && user.name ? '#007da5' : '#000000'}
                onClick={() => setOpen(!open)}
            />
            <div style={AccountContainerStyle} ref={ref}>
                <Callout open={open} className={`${calloutClass}`} id='menu' forRef={accountRef} noArrow={true}>
                    <div style={Padding}>
                        <div style={Title5}>{resources.accountDropdownHeader}</div>
                        <hr />
                        {user && user.name ? <div style={Text4}>{user.name}</div> : null}

                        {accountLinks
                            ? accountLinks.map((link: any, index: React.Key | undefined) => {
                                  const linkProps: LinkProps = {
                                      href: link.props.link.linkUrl
                                  };
                                  if (link.props.link.openInNewTab) {
                                      linkProps.target = '_blank';
                                      linkProps.rel = 'noreferrer';
                                  }
                                  return (
                                      <div key={index} style={Text4}>
                                          <a {...linkProps} style={LinkStyle}>
                                              {link.props.link.linkText}
                                          </a>
                                      </div>
                                  );
                              })
                            : false}
                        {isSignedIn ? (
                            <Secondary onClick={() => redirct()} fullWidth>
                                {resources.signOutLinkText}
                            </Secondary>
                        ) : (
                            <Secondary href={user && user.signInUrl} onClick={() => setOpen(false)} fullWidth>
                                {resources.signInLinkText}
                            </Secondary>
                        )}
                    </div>
                </Callout>
            </div>
        </>
    );
};

export default LdsAccount;
