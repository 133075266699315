import styled from 'styled-components';
// @ts-ignore
import { spacing24 } from '../../eden/eden-style-constants';

export const DesktopMenuContainer = styled.div`
    display: flex;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;
export const IconContainer = styled.span`
    cursor: pointer;
`;

export const PadBox = styled.div`
    padding: ${spacing24};
    font-family: 'Ensign:Sans', Arial, 'noto sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 700px) {
        flex-direction: column;
    }
`;

export const InlineContainer = styled.div`
    display: inline-flex;
    padding: 0 10px 40px 0;
    width: 50%;

    @media (max-width: 700px) {
        width: 100%;
    }
`;

export const SelectLabel = styled.div`
    padding: 0 0 8px 8px;
`;
