import { IRequestContext } from '@msdyn365-commerce/core-internal';
import { ILocale } from '../../interfaces/ILocale';

export const setLocale = (locale: ILocale, requestContext: IRequestContext) => {
    // TODO: Set locale in a cookie, then add it to the URL

    if (typeof window !== 'undefined') {
        const path = window.location.pathname;
        const pathSplit = path.split('/');
        pathSplit.shift();
        pathSplit.splice(0, 2, locale.country, locale.language);
        const pathName = `/${pathSplit.join('/')}`;

        window.document.cookie = `${requestContext.canonicalDomain}/${locale.country}/${locale.language};max-age=31536000; Secure`;
        requestContext.sitePath = pathName;

        window.location.assign(pathName);
    }

    // const cookieName: string = '_msdyn365___site_';

    // const cookieExpirationInSeconds: number = 5_184_000;

    // if (this.props.context.request.cookies.isConsentGiven()) {
    //     const cookieConfig = { maxAge: cookieExpirationInSeconds };
    //     this.props.context.request.cookies.set(cookieName, e.currentTarget.href, cookieConfig);
    // }
    // window.location.assign(e.currentTarget.href);
};
