/*
take an array of keyStrings (eg: Shift, x, Escape)
converts it to alinked list

returns a function that
  1. crawls linked list when key matches current node (returns false)
  2. resets list when end of link list is reached (returns true)
  3. resets list when key does not match (returns false)
*/
const createKeyCrawler = (sequence = [], keyCompareFn) => {
  const list = sequence.map(keyString => ({
    keyString
  })).map((node, i, original) => {
    node.next = original[i + 1];
    return node;
  }, []);
  const head = list[0];
  let index = head;
  return key => {
    if (!keyCompareFn(key, index.keyString)) {
      index = head;
      return false;
    } else if (!index.next) {
      index = head;
      return true;
    } else {
      index = index.next;
      return false;
    }
  };
};

export default createKeyCrawler;