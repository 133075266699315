export const countryCodeList = [
    {
        name: 'Afghanistan',
        twoLetterCode: 'AF',
        threeLetterCode: 'AFG'
    },
    {
        name: 'Åland Islands',
        twoLetterCode: 'AX',
        threeLetterCode: 'ALA'
    },
    {
        name: 'Albania',
        twoLetterCode: 'AL',
        threeLetterCode: 'ALB'
    },
    {
        name: 'Algeria',
        twoLetterCode: 'DZ',
        threeLetterCode: 'DZA'
    },
    {
        name: 'American Samoa',
        twoLetterCode: 'AS',
        threeLetterCode: 'ASM'
    },
    {
        name: 'Andorra',
        twoLetterCode: 'AD',
        threeLetterCode: 'AND'
    },
    {
        name: 'Angola',
        twoLetterCode: 'AO',
        threeLetterCode: 'AGO'
    },
    {
        name: 'Anguilla',
        twoLetterCode: 'AI',
        threeLetterCode: 'AIA'
    },
    {
        name: 'Antarctoca',
        twoLetterCode: 'AQ',
        threeLetterCode: 'ATA'
    },
    {
        name: 'Antigua and Barbuda',
        twoLetterCode: 'AG',
        threeLetterCode: 'ATG'
    },
    {
        name: 'Argentina',
        twoLetterCode: 'AR',
        threeLetterCode: 'ARG'
    },
    {
        name: 'Armenia',
        twoLetterCode: 'AM',
        threeLetterCode: 'ARM'
    },
    {
        name: 'Aruba',
        twoLetterCode: 'AW',
        threeLetterCode: 'ABW'
    },
    {
        name: 'Australia',
        twoLetterCode: 'AU',
        threeLetterCode: 'AUS'
    },
    {
        name: 'Austria',
        twoLetterCode: 'AT',
        threeLetterCode: 'AUT'
    },
    {
        name: 'Azerbaijan',
        twoLetterCode: 'AZ',
        threeLetterCode: 'AZE'
    },
    {
        name: 'Bahamas',
        twoLetterCode: 'BS',
        threeLetterCode: 'BHS'
    },
    {
        name: 'Bahrain',
        twoLetterCode: 'BH',
        threeLetterCode: 'BHR'
    },
    {
        name: 'Bangladesh',
        twoLetterCode: 'BD',
        threeLetterCode: 'BGD'
    },
    {
        name: 'Barbados',
        twoLetterCode: 'BB',
        threeLetterCode: 'BRB'
    },
    {
        name: 'Belarus',
        twoLetterCode: 'BY',
        threeLetterCode: 'BLR'
    },
    {
        name: 'Belgium',
        twoLetterCode: 'BE',
        threeLetterCode: 'BEL'
    },
    {
        name: 'Belize',
        twoLetterCode: 'BZ',
        threeLetterCode: 'BLZ'
    },
    {
        name: 'Benin',
        twoLetterCode: 'BJ',
        threeLetterCode: 'BEN'
    },
    {
        name: 'Bermuda',
        twoLetterCode: 'BM',
        threeLetterCode: 'BMU'
    },
    {
        name: 'Bhutan',
        twoLetterCode: 'BT',
        threeLetterCode: 'BTN'
    },
    {
        name: 'Bolivia',
        twoLetterCode: 'BO',
        threeLetterCode: 'BOL'
    },
    {
        name: 'Bonaire',
        twoLetterCode: 'BQ',
        threeLetterCode: 'BES'
    },
    {
        name: 'Bosnia and Herzegovina',
        twoLetterCode: 'BA',
        threeLetterCode: 'BIH'
    },
    {
        name: 'Botswana',
        twoLetterCode: 'BW',
        threeLetterCode: 'BWA'
    },
    {
        name: 'Bouvet Island',
        twoLetterCode: 'BV',
        threeLetterCode: 'BVT'
    },
    {
        name: 'Brazil',
        twoLetterCode: 'BR',
        threeLetterCode: 'BRA'
    },
    {
        name: 'British Indian Ocean Territory',
        twoLetterCode: 'IO',
        threeLetterCode: 'IOT'
    },
    {
        name: 'Brunei',
        twoLetterCode: 'BN',
        threeLetterCode: 'BRN'
    },
    {
        name: 'Bulgaria',
        twoLetterCode: 'BG',
        threeLetterCode: 'BGR'
    },
    {
        name: 'Burkina Faso',
        twoLetterCode: 'BF',
        threeLetterCode: 'BFA'
    },
    {
        name: 'Burundi',
        twoLetterCode: 'BI',
        threeLetterCode: 'BDI'
    },
    {
        name: 'Cabo Verde',
        twoLetterCode: 'CV',
        threeLetterCode: 'CPV'
    },
    {
        name: 'Cambodia',
        twoLetterCode: 'KH',
        threeLetterCode: 'KHM'
    },
    {
        name: 'Cameroon',
        twoLetterCode: 'CM',
        threeLetterCode: 'CMR'
    },
    {
        name: 'Canada',
        twoLetterCode: 'CA',
        threeLetterCode: 'CAN'
    },
    {
        name: 'Cayman Islands',
        twoLetterCode: 'KY',
        threeLetterCode: 'CYM'
    },
    {
        name: 'Central African Republic',
        twoLetterCode: 'CF',
        threeLetterCode: 'CAF'
    },
    {
        name: 'Chad',
        twoLetterCode: 'TD',
        threeLetterCode: 'TCD'
    },
    {
        name: 'Chile',
        twoLetterCode: 'CL',
        threeLetterCode: 'CHL'
    },
    {
        name: 'China',
        twoLetterCode: 'CN',
        threeLetterCode: 'CHN'
    },
    {
        name: 'Christmas Island',
        twoLetterCode: 'CX',
        threeLetterCode: 'CXR'
    },
    {
        name: 'Cocos Islands',
        twoLetterCode: 'CC',
        threeLetterCode: 'CCK'
    },
    {
        name: 'Colombia',
        twoLetterCode: 'CO',
        threeLetterCode: 'COL'
    },
    {
        name: 'Comoros',
        twoLetterCode: 'KM',
        threeLetterCode: 'COM'
    },
    {
        name: 'Congo',
        twoLetterCode: 'CG',
        threeLetterCode: 'COG'
    },
    {
        name: 'DR Congo',
        twoLetterCode: 'CD',
        threeLetterCode: 'COD'
    },
    {
        name: 'Cook Islands',
        twoLetterCode: 'CK',
        threeLetterCode: 'COK'
    },
    {
        name: 'Costa Rica',
        twoLetterCode: 'CR',
        threeLetterCode: 'CRI'
    },
    {
        name: `Côte d'Ivoire`,
        twoLetterCode: 'CI',
        threeLetterCode: 'CIV'
    },
    {
        name: 'Croatia',
        twoLetterCode: 'HR',
        threeLetterCode: 'HRV'
    },
    {
        name: 'Cuba',
        twoLetterCode: 'CU',
        threeLetterCode: 'CUB'
    },
    {
        name: 'Curaçao',
        twoLetterCode: 'CW',
        threeLetterCode: 'CUW'
    },
    {
        name: 'Cyprus',
        twoLetterCode: 'CY',
        threeLetterCode: 'CYP'
    },
    {
        name: 'Czech Republic',
        twoLetterCode: 'CZ',
        threeLetterCode: 'CZE'
    },
    {
        name: 'Denmark',
        twoLetterCode: 'DK',
        threeLetterCode: 'DNK'
    },
    {
        name: 'Djibouti',
        twoLetterCode: 'DJ',
        threeLetterCode: 'DJI'
    },
    {
        name: 'Dominica',
        twoLetterCode: 'DM',
        threeLetterCode: 'DMA'
    },
    {
        name: 'Dominican Republic',
        twoLetterCode: 'DO',
        threeLetterCode: 'DOM'
    },
    {
        name: 'Ecuador',
        twoLetterCode: 'EC',
        threeLetterCode: 'ECU'
    },
    {
        name: 'Egypt',
        twoLetterCode: 'EG',
        threeLetterCode: 'EGY'
    },
    {
        name: 'El Salvador',
        twoLetterCode: 'SV',
        threeLetterCode: 'SLV'
    },
    {
        name: 'Equatorial Guinea',
        twoLetterCode: 'GQ',
        threeLetterCode: 'GNQ'
    },
    {
        name: 'Eritrea',
        twoLetterCode: 'ER',
        threeLetterCode: 'ERI'
    },
    {
        name: 'Estonia',
        twoLetterCode: 'EE',
        threeLetterCode: 'EST'
    },
    {
        name: 'Eswatini',
        twoLetterCode: 'SZ',
        threeLetterCode: 'SWZ'
    },
    {
        name: 'Ethiopia',
        twoLetterCode: 'ET',
        threeLetterCode: 'ETH'
    },
    {
        name: 'Falkland Islands',
        twoLetterCode: 'FK',
        threeLetterCode: 'FLK'
    },
    {
        name: 'Faroe Islands',
        twoLetterCode: 'FO',
        threeLetterCode: 'FRO'
    },
    {
        name: 'Fiji',
        twoLetterCode: 'FJ',
        threeLetterCode: 'FJI'
    },
    {
        name: 'Finland',
        twoLetterCode: 'FI',
        threeLetterCode: 'FIN'
    },
    {
        name: 'France',
        twoLetterCode: 'FR',
        threeLetterCode: 'FRA'
    },
    {
        name: 'French Guiana',
        twoLetterCode: 'GF',
        threeLetterCode: 'GUF'
    },
    {
        name: 'French Polynesia',
        twoLetterCode: 'PF',
        threeLetterCode: 'PYF'
    },
    {
        name: 'French Southern Territories',
        twoLetterCode: 'TF',
        threeLetterCode: 'ATF'
    },
    {
        name: 'Gabon',
        twoLetterCode: 'GA',
        threeLetterCode: 'GAB'
    },
    {
        name: 'Gambia',
        twoLetterCode: 'GM',
        threeLetterCode: 'GMB'
    },
    {
        name: 'Georgia',
        twoLetterCode: 'GE',
        threeLetterCode: 'GEO'
    },
    {
        name: 'Germany',
        twoLetterCode: 'DE',
        threeLetterCode: 'DEU'
    },
    {
        name: 'Ghana',
        twoLetterCode: 'GH',
        threeLetterCode: 'GHA'
    },
    {
        name: 'Gibraltar',
        twoLetterCode: 'GI',
        threeLetterCode: 'GIB'
    },
    {
        name: 'Greece',
        twoLetterCode: 'GR',
        threeLetterCode: 'GRC'
    },
    {
        name: 'Greenland',
        twoLetterCode: 'GL',
        threeLetterCode: 'GRL'
    },
    {
        name: 'Grenada',
        twoLetterCode: 'GD',
        threeLetterCode: 'GRD'
    },
    {
        name: 'Guadeloupe',
        twoLetterCode: 'GP',
        threeLetterCode: 'GLP'
    },
    {
        name: 'Guam',
        twoLetterCode: 'GU',
        threeLetterCode: 'GUM'
    },
    {
        name: 'Guatemala',
        twoLetterCode: 'GT',
        threeLetterCode: 'GTM'
    },
    {
        name: 'Guernsey',
        twoLetterCode: 'GG',
        threeLetterCode: 'GGY'
    },
    {
        name: 'Guinea',
        twoLetterCode: 'GN',
        threeLetterCode: 'GIN'
    },
    {
        name: 'Guinea-Bissau',
        twoLetterCode: 'GW',
        threeLetterCode: 'GNB'
    },
    {
        name: 'Guyana',
        twoLetterCode: 'GY',
        threeLetterCode: 'GUY'
    },
    {
        name: 'Haiti',
        twoLetterCode: 'HT',
        threeLetterCode: 'HTI'
    },
    {
        name: 'Heard Island and McDonald Islands',
        twoLetterCode: 'HM',
        threeLetterCode: 'HMD'
    },
    {
        name: 'Honduras',
        twoLetterCode: 'HN',
        threeLetterCode: 'HND'
    },
    {
        name: 'Hong Kong',
        twoLetterCode: 'HK',
        threeLetterCode: 'HKG'
    },
    {
        name: 'Hungary',
        twoLetterCode: 'HU',
        threeLetterCode: 'HUN'
    },
    {
        name: 'Iceland',
        twoLetterCode: 'IS',
        threeLetterCode: 'ISL'
    },
    {
        name: 'India',
        twoLetterCode: 'IN',
        threeLetterCode: 'IND'
    },
    {
        name: 'Indonesia',
        twoLetterCode: 'ID',
        threeLetterCode: 'IDN'
    },
    {
        name: 'Iran',
        twoLetterCode: 'IR',
        threeLetterCode: 'IRN'
    },
    {
        name: 'Iraq',
        twoLetterCode: 'IQ',
        threeLetterCode: 'IRQ'
    },
    {
        name: 'Ireland',
        twoLetterCode: 'IE',
        threeLetterCode: 'IRL'
    },
    {
        name: 'Isle of Man',
        twoLetterCode: 'IM',
        threeLetterCode: 'IMN'
    },
    {
        name: 'Israel',
        twoLetterCode: 'IL',
        threeLetterCode: 'ISR'
    },
    {
        name: 'Italy',
        twoLetterCode: 'IT',
        threeLetterCode: 'ITA'
    },
    {
        name: 'Jamaica',
        twoLetterCode: 'JM',
        threeLetterCode: 'JAM'
    },
    {
        name: 'Japan',
        twoLetterCode: 'JP',
        threeLetterCode: 'JPN'
    },
    {
        name: 'Jersey',
        twoLetterCode: 'JE',
        threeLetterCode: 'JEY'
    },
    {
        name: 'Jordan',
        twoLetterCode: 'JO',
        threeLetterCode: 'JOR'
    },
    {
        name: 'Kazakhstan',
        twoLetterCode: 'KZ',
        threeLetterCode: 'KAZ'
    },
    {
        name: 'Kenya',
        twoLetterCode: 'KE',
        threeLetterCode: 'KEN'
    },
    {
        name: 'Kiribati',
        twoLetterCode: 'KI',
        threeLetterCode: 'KIR'
    },
    {
        name: 'Kosovo',
        twoLetterCode: 'XK',
        threeLetterCode: 'KOS'
    },
    {
        name: 'Kuwait',
        twoLetterCode: 'KW',
        threeLetterCode: 'KWT'
    },
    {
        name: 'Kyrgyzstan',
        twoLetterCode: 'KG',
        threeLetterCode: 'KGZ'
    },
    {
        name: 'Laos',
        twoLetterCode: 'LA',
        threeLetterCode: 'LAO'
    },
    {
        name: 'Latvia',
        twoLetterCode: 'LV',
        threeLetterCode: 'LVA'
    },
    {
        name: 'Lebanon',
        twoLetterCode: 'LB',
        threeLetterCode: 'LBN'
    },
    {
        name: 'Lesotho',
        twoLetterCode: 'LS',
        threeLetterCode: 'LSO'
    },
    {
        name: 'Liberia',
        twoLetterCode: 'LR',
        threeLetterCode: 'LBR'
    },
    {
        name: 'Libya',
        twoLetterCode: 'LY',
        threeLetterCode: 'LBY'
    },
    {
        name: 'Liechtenstein',
        twoLetterCode: 'LI',
        threeLetterCode: 'LIE'
    },
    {
        name: 'Lithuania',
        twoLetterCode: 'LT',
        threeLetterCode: 'LTU'
    },
    {
        name: 'Luxembourg',
        twoLetterCode: 'LU',
        threeLetterCode: 'LUX'
    },
    {
        name: 'Macao',
        twoLetterCode: 'MO',
        threeLetterCode: 'MAC'
    },
    {
        name: 'Macedonia',
        twoLetterCode: 'MK',
        threeLetterCode: 'MKD'
    },
    {
        name: 'Madagascar',
        twoLetterCode: 'MG',
        threeLetterCode: 'MDG'
    },
    {
        name: 'Malawi',
        twoLetterCode: 'MW',
        threeLetterCode: 'MWI'
    },
    {
        name: 'Malaysia',
        twoLetterCode: 'MY',
        threeLetterCode: 'MYS'
    },
    {
        name: 'Maldives',
        twoLetterCode: 'MV',
        threeLetterCode: 'MDV'
    },
    {
        name: 'Mali',
        twoLetterCode: 'ML',
        threeLetterCode: 'MLI'
    },
    {
        name: 'Malta',
        twoLetterCode: 'MT',
        threeLetterCode: 'MLT'
    },
    {
        name: 'Marshall Islands',
        twoLetterCode: 'MH',
        threeLetterCode: 'MHL'
    },
    {
        name: 'Martinique',
        twoLetterCode: 'MQ',
        threeLetterCode: 'MTQ'
    },
    {
        name: 'Mauritania',
        twoLetterCode: 'MR',
        threeLetterCode: 'MRT'
    },
    {
        name: 'Mauritius',
        twoLetterCode: 'MU',
        threeLetterCode: 'MUS'
    },
    {
        name: 'Mayotte',
        twoLetterCode: 'YT',
        threeLetterCode: 'MYT'
    },
    {
        name: 'Mexico',
        twoLetterCode: 'MX',
        threeLetterCode: 'MEX'
    },
    {
        name: 'Micronesia',
        twoLetterCode: 'FM',
        threeLetterCode: 'FSM'
    },
    {
        name: 'Moldova',
        twoLetterCode: 'MD',
        threeLetterCode: 'MDA'
    },
    {
        name: 'Monaco',
        twoLetterCode: 'MC',
        threeLetterCode: 'MCO'
    },
    {
        name: 'Mongolia',
        twoLetterCode: 'MN',
        threeLetterCode: 'MNG'
    },
    {
        name: 'Montenegro',
        twoLetterCode: 'ME',
        threeLetterCode: 'MNE'
    },
    {
        name: 'Montserrat',
        twoLetterCode: 'MS',
        threeLetterCode: 'MSR'
    },
    {
        name: 'Morocco',
        twoLetterCode: 'MA',
        threeLetterCode: 'MAR'
    },
    {
        name: 'Mozambique',
        twoLetterCode: 'MZ',
        threeLetterCode: 'MOZ'
    },
    {
        name: 'Myanmar',
        twoLetterCode: 'MM',
        threeLetterCode: 'MMR'
    },
    {
        name: 'Namibia',
        twoLetterCode: 'NA',
        threeLetterCode: 'NAM'
    },
    {
        name: 'Nauru',
        twoLetterCode: 'NR',
        threeLetterCode: 'NRU'
    },
    {
        name: 'Nepal',
        twoLetterCode: 'NP',
        threeLetterCode: 'NPL'
    },
    {
        name: 'Netherlands',
        twoLetterCode: 'NL',
        threeLetterCode: 'NLD'
    },
    {
        name: 'New Caledonia',
        twoLetterCode: 'NC',
        threeLetterCode: 'NCL'
    },
    {
        name: 'New Zealand',
        twoLetterCode: 'NZ',
        threeLetterCode: 'NZL'
    },
    {
        name: 'Nicaragua',
        twoLetterCode: 'NI',
        threeLetterCode: 'NIC'
    },
    {
        name: 'Niger',
        twoLetterCode: 'NE',
        threeLetterCode: 'NER'
    },
    {
        name: 'Nigeria',
        twoLetterCode: 'NG',
        threeLetterCode: 'NGA'
    },
    {
        name: 'Niue',
        twoLetterCode: 'NU',
        threeLetterCode: 'NIU'
    },
    {
        name: 'Norfold Island',
        twoLetterCode: 'NF',
        threeLetterCode: 'NFK'
    },
    {
        name: 'North Korea',
        twoLetterCode: 'KP',
        threeLetterCode: 'PRK'
    },
    {
        name: 'North Macedonia',
        twoLetterCode: 'MK',
        threeLetterCode: 'MKD'
    },
    {
        name: 'Northern Mariana Islands',
        twoLetterCode: 'MP',
        threeLetterCode: 'MNP'
    },
    {
        name: 'Norway',
        twoLetterCode: 'NO',
        threeLetterCode: 'NOR'
    },
    {
        name: 'Oman',
        twoLetterCode: 'OM',
        threeLetterCode: 'OMN'
    },
    {
        name: 'Pakistan',
        twoLetterCode: 'PK',
        threeLetterCode: 'PAK'
    },
    {
        name: 'Palau',
        twoLetterCode: 'PW',
        threeLetterCode: 'PLW'
    },
    {
        name: 'Palestine',
        twoLetterCode: 'PS',
        threeLetterCode: 'PSE'
    },
    {
        name: 'Panama',
        twoLetterCode: 'PA',
        threeLetterCode: 'PAN'
    },
    {
        name: 'Papua New Guinea',
        twoLetterCode: 'PG',
        threeLetterCode: 'PNG'
    },
    {
        name: 'Paraguay',
        twoLetterCode: 'PY',
        threeLetterCode: 'PRY'
    },
    {
        name: 'Peru',
        twoLetterCode: 'PE',
        threeLetterCode: 'PER'
    },
    {
        name: 'Philippines',
        twoLetterCode: 'PH',
        threeLetterCode: 'PHL'
    },
    {
        name: 'Poland',
        twoLetterCode: 'PL',
        threeLetterCode: 'POL'
    },
    {
        name: 'Portugal',
        twoLetterCode: 'PT',
        threeLetterCode: 'PRT'
    },
    {
        name: 'Puerto Rico',
        twoLetterCode: 'PR',
        threeLetterCode: 'PRI'
    },
    {
        name: 'Qatar',
        twoLetterCode: 'QA',
        threeLetterCode: 'QAT'
    },
    {
        name: 'Réunion',
        twoLetterCode: 'RE',
        threeLetterCode: 'REU'
    },
    {
        name: 'Romania',
        twoLetterCode: 'RO',
        threeLetterCode: 'ROU'
    },
    {
        name: 'Russia',
        twoLetterCode: 'RU',
        threeLetterCode: 'RUS'
    },
    {
        name: 'Rwanda',
        twoLetterCode: 'RW',
        threeLetterCode: 'RWA'
    },
    {
        name: 'Saint Barthélemy',
        twoLetterCode: 'BL',
        threeLetterCode: 'BLM'
    },
    {
        name: 'Saint Helena',
        twoLetterCode: 'SH',
        threeLetterCode: 'SHN'
    },
    {
        name: 'Saint Kitts and Nevis',
        twoLetterCode: 'KN',
        threeLetterCode: 'KNA'
    },
    {
        name: 'Saint Lucia',
        twoLetterCode: 'LC',
        threeLetterCode: 'LCA'
    },
    {
        name: 'Saint Martin',
        twoLetterCode: 'MF',
        threeLetterCode: 'MAF'
    },
    {
        name: 'Saint Pierre and Miquelon',
        twoLetterCode: 'PM',
        threeLetterCode: 'SPM'
    },
    {
        name: 'Saint Vincent and the Grenadines',
        twoLetterCode: 'VC',
        threeLetterCode: 'VCT'
    },
    {
        name: 'Samoa',
        twoLetterCode: 'WS',
        threeLetterCode: 'WSM'
    },
    {
        name: 'San Marino',
        twoLetterCode: 'SM',
        threeLetterCode: 'SMR'
    },
    {
        name: 'Sao Tome and Principe',
        twoLetterCode: 'ST',
        threeLetterCode: 'STP'
    },
    {
        name: 'Saudi Arabia',
        twoLetterCode: 'SA',
        threeLetterCode: 'SAU'
    },
    {
        name: 'Senegal',
        twoLetterCode: 'SN',
        threeLetterCode: 'SEN'
    },
    {
        name: 'Serbia',
        twoLetterCode: 'RS',
        threeLetterCode: 'SRB'
    },
    {
        name: 'Seychelles',
        twoLetterCode: 'SC',
        threeLetterCode: 'SYC'
    },
    {
        name: 'Sierra Leone',
        twoLetterCode: 'SL',
        threeLetterCode: 'SLE'
    },
    {
        name: 'Singapore',
        twoLetterCode: 'SG',
        threeLetterCode: 'SGP'
    },
    {
        name: 'Sint Maarten (Dutch part)',
        twoLetterCode: 'SX',
        threeLetterCode: 'SXM'
    },
    {
        name: 'Slovakia',
        twoLetterCode: 'SK',
        threeLetterCode: 'SVK'
    },
    {
        name: 'Slovenia',
        twoLetterCode: 'SI',
        threeLetterCode: 'SVN'
    },
    {
        name: 'Solomon Islands',
        twoLetterCode: 'SB',
        threeLetterCode: 'SLB'
    },
    {
        name: 'Somalia',
        twoLetterCode: 'SO',
        threeLetterCode: 'SOM'
    },
    {
        name: 'South Africa',
        twoLetterCode: 'ZA',
        threeLetterCode: 'ZAF'
    },
    {
        name: 'South Georgia',
        twoLetterCode: 'GS',
        threeLetterCode: 'SGS'
    },
    {
        name: 'South Korea',
        twoLetterCode: 'KR',
        threeLetterCode: 'KOR'
    },
    {
        name: 'South Sudan',
        twoLetterCode: 'SS',
        threeLetterCode: 'SSD'
    },
    {
        name: 'Spain',
        twoLetterCode: 'ES',
        threeLetterCode: 'ESP'
    },
    {
        name: 'Sri Lanka',
        twoLetterCode: 'LK',
        threeLetterCode: 'LKA'
    },
    {
        name: 'Sudan',
        twoLetterCode: 'SD',
        threeLetterCode: 'SDN'
    },
    {
        name: 'Suriname',
        twoLetterCode: 'SR',
        threeLetterCode: 'SUR'
    },
    {
        name: 'Svalbard and Jan Mayen[',
        twoLetterCode: 'SJ',
        threeLetterCode: 'SJM'
    },
    {
        name: 'Sweden',
        twoLetterCode: 'SE',
        threeLetterCode: 'SWE'
    },
    {
        name: 'Switzerland',
        twoLetterCode: 'CH',
        threeLetterCode: 'CHE'
    },
    {
        name: 'Syria',
        twoLetterCode: 'SY',
        threeLetterCode: 'SYR'
    },
    {
        name: 'Taiwan',
        twoLetterCode: 'TW',
        threeLetterCode: 'TWN'
    },
    {
        name: 'Tajikistan',
        twoLetterCode: 'TJ',
        threeLetterCode: 'TJK'
    },
    {
        name: 'Tanzania',
        twoLetterCode: 'TZ',
        threeLetterCode: 'TZA'
    },
    {
        name: 'Thailand',
        twoLetterCode: 'TH',
        threeLetterCode: 'THA'
    },
    {
        name: 'Timor-Leste',
        twoLetterCode: 'TL',
        threeLetterCode: 'TLS'
    },
    {
        name: 'Togo',
        twoLetterCode: 'TG',
        threeLetterCode: 'TGO'
    },
    {
        name: 'Tokelau',
        twoLetterCode: 'TK',
        threeLetterCode: 'TKL'
    },
    {
        name: 'Tonga',
        twoLetterCode: 'TO',
        threeLetterCode: 'TON'
    },
    {
        name: 'Trinidad and Tobago',
        twoLetterCode: 'TT',
        threeLetterCode: 'TTO'
    },
    {
        name: 'Tunisia',
        twoLetterCode: 'TN',
        threeLetterCode: 'TUN'
    },
    {
        name: 'Turkey',
        twoLetterCode: 'TR',
        threeLetterCode: 'TUR'
    },
    {
        name: 'Turkmenistan',
        twoLetterCode: 'TM',
        threeLetterCode: 'TKM'
    },
    {
        name: 'Turks and Caicos Islands',
        twoLetterCode: 'TC',
        threeLetterCode: 'TCA'
    },
    {
        name: 'Tuvalu',
        twoLetterCode: 'TV',
        threeLetterCode: 'TUV'
    },
    {
        name: 'Uganda',
        twoLetterCode: 'UG',
        threeLetterCode: 'UGA'
    },
    {
        name: 'Ukraine',
        twoLetterCode: 'UA',
        threeLetterCode: 'UKR'
    },
    {
        name: 'United Arab Emirates',
        twoLetterCode: 'AE',
        threeLetterCode: 'ARE'
    },
    {
        name: 'United Kingdom',
        twoLetterCode: 'GB',
        threeLetterCode: 'GBR'
    },
    {
        name: 'United States',
        twoLetterCode: 'US',
        threeLetterCode: 'USA'
    },
    {
        name: 'Uruguay',
        twoLetterCode: 'UY',
        threeLetterCode: 'URY'
    },
    {
        name: 'Uzbekistan',
        twoLetterCode: 'UZ',
        threeLetterCode: 'UZB'
    },
    {
        name: 'Vanuatu',
        twoLetterCode: 'VU',
        threeLetterCode: 'VUT'
    },
    {
        name: 'Vatican City',
        twoLetterCode: 'VA',
        threeLetterCode: 'VAT'
    },
    {
        name: 'Venezuela',
        twoLetterCode: 'VE',
        threeLetterCode: 'VEN'
    },
    {
        name: 'Vietnam',
        twoLetterCode: 'VN',
        threeLetterCode: 'VNM'
    },
    {
        name: 'Virgin Islands (British)',
        twoLetterCode: 'VG',
        threeLetterCode: 'VGB'
    },
    {
        name: 'Virgin Islands (U.S)',
        twoLetterCode: 'VI',
        threeLetterCode: 'VIR'
    },
    {
        name: 'Wallis and Futuna',
        twoLetterCode: 'WF',
        threeLetterCode: 'WLF'
    },
    {
        name: 'Western Sahara',
        twoLetterCode: 'EH',
        threeLetterCode: 'ESH'
    },
    {
        name: 'Yemen',
        twoLetterCode: 'YE',
        threeLetterCode: 'YEM'
    },
    {
        name: 'Zambia',
        twoLetterCode: 'ZM',
        threeLetterCode: 'ZMB'
    },
    {
        name: 'Zimbabwe',
        twoLetterCode: 'ZW',
        threeLetterCode: 'ZWE'
    }
];
